//–––––––––––––––––––––
//  IMPORT FONT
//–––––––––––––––––––––

// @font-face {
//   font-family: "ExampleFontFace";
//   src:
//     // url("../fonts/ExampleFontFace/ExampleFontFace?#iefix") format("embedded-opentype"),
//     // url("../fonts/ExampleFontFace/ExampleFontFace.ttf") format("truetype"),
//     url("../fonts/ExampleFontFace/ExampleFontFace.woff2") format("woff2"),
//     url("../fonts/ExampleFontFace/ExampleName.woff") format("woff");
//   font-display: swap;
// }

//–––––––––––––––––––––
//  FONT VARIABLES
//–––––––––––––––––––––

// $ExampleFontVariable: "ExampleFontFace", Arial, sans-serif;

$Helvetica: Helvetica, Arial, sans-serif; 

//–––––––––––––––––––––
//  MIXIN FONT
//–––––––––––––––––––––

// @mixin exampleFontMixin {
//   font-family: $ExampleFontVariable;
//   font-style: normal;
//   font-weight: 400
// }

@mixin fontHelveticaRegular {
    font-family: $Helvetica;
    font-weight: 400; 
}

@mixin fontHelveticaBold {
    font-family: $Helvetica;
    font-weight: 700; 
}

//–––––––––––––––––––––
//  MIXIN TYPO
//–––––––––––––––––––––

@mixin t-project-title {
    @include fontHelveticaRegular;
    font-size: rem(30px); 
    margin-bottom: 25px;
    color: $white;

    @include respond-above(s){
        font-size: rem(36px); 
    }
}

@mixin t-project-cta {
    @include fontHelveticaRegular;
    font-size: rem(14px);
    margin-bottom: 8px;
    color: $white;
    text-decoration: underline;
    text-transform: uppercase;

    @include respond-above(s){
        font-size: rem(16px);
    }
}