@import '../../styles';

.projectAwards {
    @include project-section;
}

.projectAwards__title {
    @include t-project-title;
    display: none;
}

.projectAwards__info {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
}

.projectAwards__award {
    display: block;
    position: relative;
    width: 33.33%;
    padding-bottom: 30px;
    padding-right: 30px;
}

.projectAwards__img {
    display: block;
    position: relative;
    width: 80%;
    padding-bottom: 10px;
}

.projectAwards__text {
    display: none;
    @include fontHelveticaRegular;
    font-size: rem(16px);
    color: $white
}