@import '../../styles';

.footer {
    padding: 0 $padding-mobile;

    @include respond-above(s) {
        padding: 0 $padding-tablet;
    }

    @include respond-above(m) {
        padding: 0 $padding-desktop;
    }
}

.footer__wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin: 80px 0 40px;
    padding-top: 20px;

    @include respond-above(s) {
        padding-top: 40px;
    }

    &::before {
        content: " ";
        position: absolute;
        top: 0;
        width: 100%;
        height: 1px;
        overflow: hidden;
        background-color: $white;
    }
}

.footer__element {
    padding: 0;
    position: relative;

    @include respond-above(m) {
        padding: 0 15px;
    }
}

.footer__element--logo {
    width: 20%;
    margin: 18px 0;

    @include respond-above(m) {
        margin: 0;
        width: 15%;
    }
}

.footer__element--contact {
    display: flex;
    align-items: flex-start;
    width: 80%;
    margin: 18px 0;
    padding-left: 30px;

    @include respond-above(m) {
        margin: 0;
        width: 35%;
    }
}

.footer__element--address {
    width: 100%;
    margin: 18px 0;

    @include respond-above(m) {
        margin: 0;
        width: 35%;
    }
}

.footer__element--social {
    width: 100%;
    margin: 18px 0;

    @include respond-above(m) {
        margin: 0;
        width: 15%;
    }
}

.footer__logo {
    display: block;
    position: relative;
    width: 100%;
    max-width: 118px;
}

.footer__elementContact {
    display: block;
    @include fontHelveticaRegular;
    color: $white;
}

.footer__elementAddress {
    display: block;
    @include fontHelveticaRegular;
    color: $white;
}

.footer__elementSocial {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include fontHelveticaRegular;
    color: $white;

    @include respond-above(m) {
        display: block;
    }
}

.footer__elementContactTitle {
    @include fontHelveticaBold;
    font-size: 26px;
    margin-bottom: 5px;

    @include respond-above(s) {
        font-size: 35px;
    }

    @include respond-above(m) {
        margin-bottom: 15px;
    }
}

.footer__elementContactInfo {
    display: block;
    color: $white;
    font-size: 16px;
    margin-bottom: 5px;

    @include respond-above(s) {
        font-size: 20px;
    }

    @include respond-above(m) {
        margin-bottom: 15px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.footer__elementAddressTitle {
    @include fontHelveticaBold;
    font-size: 16px;

    @include respond-above(s) {
        font-size: 20px;
    }
}

.footer__elementAddressInfo {
    @include fontHelveticaRegular;
    font-size: 14px;
   

    @include respond-above(s) {
        font-size: 17px;
    }
}
.footer__elementAddressInfo--bold {
    @include fontHelveticaBold;
    font-size: 14px;
    margin: 5px 0;
    @include respond-above(s) {
        font-size: 17px;
    }
}

.footer__elementSocialInfo {
    position: relative;
    display: block;
    color: $white;
    margin-bottom: 10px;
    width: 50%;
    font-size: 16px;

    @include respond-above(s) {
        width: auto;
        margin-right: 20px;
        font-size: 23px;
        @include underline-link;
    }

    @include respond-above(m) {
        width: fit-content;
        margin-left: 0;
    }
}