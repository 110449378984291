@import '../../styles';

.menu {
    display: block;
    position: relative;
}

// HAMBURGER

.hamburger {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;

    @include respond-above(s) {
        top: 80px;
        right: 80px;
    }
}

.hamburger__toggle {
    background: transparent;
    border: none;
}

.line {
    width: 35px;
    height: 3px;
    background-color: $white;
    display: block;
    margin: 6px auto;
    transition: $transition-ease;

    @include respond-above(s) {
        width: 55px;
        height: 3px;
    }
}

.hamburger__icon {
    &:hover {
        cursor: pointer;
    }

    .menu--open & {
        .line:nth-child(2) {
            opacity: 0;
        }

        .line:nth-child(1) {
            transform: translateY(9px) rotate(30deg);
        }

        .line:nth-child(3) {
            transform: translateY(-9px) rotate(-30deg);
        }
    }
}

// MENU

.menu__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background: $overlay-black;
    transition: $transition-ease;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    height: calc((var(--vh, 1vh) * 100));

    @include respond-above(s) {
        height: 100vh;
    }

    .menu--open & {
        opacity: 1;
        pointer-events: visible;
    }
}

.menu__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $padding-mobile;

    @include respond-above(s) {
        padding: $padding-tablet;
    }

    @include respond-above(m) {
        padding: $padding-desktop;
    }
}

.menu__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include respond-above(m) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.menu__voice {
    @include fontHelveticaBold;
    font-size: rem(40px);
    color: $white;

    @include respond-above(s) {
        font-size: rem(52px);
    }

    @include respond-above(m) {
        font-size: rem(80px);
    }
}

.menu__social {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @include respond-above(m) {
        justify-content: flex-start;
    }
}

.menu__linkSocial {
    display: block;
    width: 48px;
    margin-right: 40px;

    &:last-child {
        margin-right: 0;
    }
}

.menu__imgSocial {
    width: 80%;
}

// BANNER

.bannerMain {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc((var(--vh, 1vh) * 100));

    @include respond-above(s) {
        height: 100vh;
    }
}

.bannerMain__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.bannerMain__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: $transition-ease;
    opacity: 1;
    pointer-events: visible;
    z-index: 1;
    padding: $padding-mobile;

    @include respond-above(s) {
        padding: $padding-tablet;
    }

    @include respond-above(m) {
        padding: $padding-desktop;
    }

    .menu--open & {
        opacity: 0;
        pointer-events: none;
    }
}

.bannerMain__logo {
    display: block;
    width: 180px;

    @include respond-above(s) {
        width: 250px;
    }
}

.bannerMain__info {
    padding-left: 20px;
    display: block;
    position: relative;

    @include respond-above(s) {
        margin-bottom: 100px;
    }

    &:after {
        content: "";
        height: 100%;
        width: 2px;
        background-color: $white;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.bannerMain__title {
    @include fontHelveticaBold;
    font-size: rem(32px);
    color: $white;

    @include respond-above(s) {
        font-size: rem(40px);
    }

    @include respond-above(m) {
        font-size: rem(52px);
    }
}


.bannerMain__subtitle {
    @include fontHelveticaRegular;
    font-size: rem(26px);
    color: $white;

    @include respond-above(s) {
        font-size: rem(32px);
    }

    @include respond-above(m) {
        font-size: rem(40px);
    }
}

// BACKGROUND

.bannerMain__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}