@import '../../styles';

.partners__slide {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 20px;
    @include respond-above(s){
        padding: 20px 60px 20px 20px;
    }
}

.slider--partners {
    @include respond-above(m) {
        padding-top: 120px;
    }
}

.sliderPartner__titleWrapper {
    display: block;
    width: 100%;

    @include respond-above(m) {
        width: 60%;
    }
}

.sliderPartner__title {
    @include fontHelveticaRegular;
    font-size: 15px;
    color: $white;
    margin-bottom: 20px;
    margin-left: $padding-mobile;
    margin-right: $padding-mobile;

    @include respond-above(s) {
        margin-bottom: 100px;
        margin-left: $padding-tablet;
        margin-right: $padding-tablet;
        font-size: 2px;
    }

    @include respond-above(m) {
        margin-bottom: $padding-desktop;
        margin-left: $padding-desktop;
        margin-right: $padding-desktop;
        font-size: 27px;
    }
}

.sliderPartner__splide {
    display: block;
    position: relative;
    // padding: 80px $padding-mobile;

    // @include respond-above(s) {
    //     padding: 100px $padding-tablet;
    // }

    // @include respond-above(m) {
    //     padding: $padding-desktop;
    // }
}