@import '../../styles';

.about {
    display: block;
    position: relative;
    width: 100vw;
    height: 80vh;
    padding: 30px;

    @include respond-above(s){
        padding: 80px;
    }

    @include respond-above(m){
        padding: 100px;
        height: 100vh;
    }
}

.about__content {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.about__contentMaskOne {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.about__contentMaskTwo {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.about__citeOne {
    @include fontHelveticaRegular;
    color: $white;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    font-size: 24px;

    @include respond-above(s){
        font-size: 32px;
    }

    @include respond-above(m){
        font-size: 40px;
    }

    .about--enter & {
        opacity: 1;
        transform: translateY(0);
    }

    &:nth-child(1) {
        transition: all 0.8s ease;
    }

    &:nth-child(2) {
        transition: all 0.8s ease 0.2s;
    }

    &:nth-child(3) {
        transition: all 0.8s ease 0.4s;
    }

    &:nth-child(4) {
        transition: all 0.8s ease 0.6s;
    }

    .about--open & {
        opacity: 0;
    }
}

.about__citeTwo {
    @include fontHelveticaRegular;
    color: $white;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    font-size: 24px;

    @include respond-above(s){
        font-size: 32px;
    }

    @include respond-above(m){
        font-size: 40px;
    }

    .about--enter & {
        .about--open & {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &:nth-child(1) {
        transition: all 0.8s ease;
    }

    &:nth-child(2) {
        transition: all 0.8s ease 0.2s;
    }

    &:nth-child(3) {
        transition: all 0.8s ease 0.4s;
    }

    &:nth-child(4) {
        transition: all 0.8s ease 0.6s;
    }
}

.about__buttonMore {
    @include fontHelveticaRegular;
    font-size: 24px;
    color: $white;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 30px;
  
    @include respond-above(s){
        font-size: 32px;
    }

    @include respond-above(m){
        font-size: 40px;
    }
}

.about__next {
    margin-right: 10px;
}

.about__goTo {
    display: flex;
}