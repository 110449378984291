@import '../../styles';

.infoAbout {
    display: block;
    position: relative;
    width: 100vw;
    background-color: $black-pure;
}

// BACKGROUND

.infoAbout__background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
}

.infoAbout__img {
    display: block;
    width: 100%;
    height: 100%;
}

// BACK HOME

.infoAbout__backHome {
    position: absolute;
    top: $padding-mobile;
    left: $padding-mobile;
    z-index: 1;

    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.infoAbout__iconBackHome {
    display: block;
    position: relative;
    width: 32px;
    margin-right: 25px
}

.infoAbout__textBackHome {
    @include fontHelveticaRegular;
    text-transform: uppercase;
    color: $white;
    font-size: rem(20px);
}

// CONTENT

.infoAbout__content {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    padding: $padding-mobile;

    @include respond-above(m) {
        padding: $padding-tablet;
    }

    @include respond-above(m) {
        padding: $padding-desktop;
    }
}

.infoAbout__title {
    @include fontHelveticaBold;
    font-size: rem(50px);
    width: 100%;
    color: $white;
    padding: 140px 0 80px;

    @include respond-above(m) {
        font-size: rem(80px);
        width: 60%;
        padding: 240px 0 180px;
    }
}

// MAIN

.infoAbout__main {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    @include respond-above(m) {
        flex-direction: row
    }
}


// TEXT

.infoAbout__text {
    @include fontHelveticaRegular;
    font-size: rem(16px);
    line-height: 1.6;
    color: $white;

    @include respond-above(m) {
        font-size: rem(20px);
    }

    &.infoAbout__text--marginTop {
        margin-top: 40px;
    }

    b {
        font-weight: 700;
    }
}

// LEFT

.infoAbout__left {
    display: block;
    width: 100%;

    @include respond-above(m) {
        width: 30%;
    }
}

.infoAbout__button {
    display: block;
    background: none;
    border: none;
    @include fontHelveticaBold;
    color: $white;
    opacity: 0.4;
    transition: $transition-ease;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: rem(26px);

    @include respond-above(m) {
        font-size: rem(32px);
    }

    &.isActive {
        opacity: 1;
    }
}

// RIGHT 

.infoAbout__right {
    display: block;
    width: 100%;
    margin-top: 30px;

    @include respond-above(m) {
        margin-top: 0;
        width: 70%;
        padding-left: 60px;
    }
}

.infoAbout__about {
    display: block;
    position: relative;
    width: 100%;
}

.infoAbout__scrolling {
    padding-right: 20px;

    @include respond-above(m) {
        overflow-y: scroll;
        height: 400px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $grey;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $white;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $green;
    }
}

.infoAbout__methodologyTitle {
    @include fontHelveticaBold;
    font-size: rem(24px);
    color: $white;
    margin-bottom: 22px;

    @include respond-above(m) {
        font-size: rem(30px);
    }
}

.infoAbout__list {
    margin-top: 50px;
}

.infoAbout__itemList {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px 0 20px;
    border-top: 1px solid $white;

    @include respond-above(s) {
        flex-direction: row;
        padding: 10px 0 40px;
    }
}

.infoAbout__titleItemList {
    width: 100%;
    @include fontHelveticaBold;
    font-size: rem(24px);
    text-transform: uppercase;
    color: $white;
    padding-right: 30px;

    @include respond-above(s) {
        width: 50%;
    }

    @include respond-above(m) {
        font-size: rem(30px);
    }


}

.infoAbout__descItemList {
    @include fontHelveticaRegular;
    font-size: rem(16px);
    width: 100%;
    color: $white;
    margin-top: 20px;

    @include respond-above(s) {
        margin-top: 0;
        width: 50%;
    }

    @include respond-above(m) {
        font-size: rem(18px);
    }
}

.infoAbout__asap {
    display: block;
    position: relative;
    width: 100%;
    margin: 50px auto;
    max-width: 500px;

    @include respond-above(m) {
        max-width: none;
        margin: 80px 0;
    }
}