@import '../../styles';

.projectDownloads {
    @include project-section;
    margin-bottom: 0;
}

.projectDownloads__title {
    @include t-project-title;
    display: none;
}

.projectDownloads__textVideo {
    @include t-project-cta;
}