*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  &.blockScroll {
    overflow: hidden;
  }
}

body {
  background-color: $black;

  * {
    &::selection  {
      background-color: $green;
    }
  }
}

// NO PAGE

.noPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .noPage__title {
    @include fontHelveticaBold;
    color: $white;
    font-size: rem(40px);
  }
}

// PROJECT LAYOUT

.projectLayout {
  display: block;
  position: relative;
}

.projectLayoutContent {
  padding: 32px 32px 100px 32px;

  @include respond-above(m) {
    padding: 80px;
  }
}

.projectLayout__asterisco {
  @include fontHelveticaRegular;
  font-size: rem(17px);
  color: $white;
  margin-bottom: 40px;
  width: 100%;

  @include respond-above(m){
    width: 30%;
    line-height: 20px;
    letter-spacing: 2px;
  }
}

// STYLE SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: $grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $green;
}