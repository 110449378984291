@import '../../styles';

.bannerSlider {
    display: block;
    position: relative;
    width: 100vw;
    height: 50vh;

    @include respond-above(m) {
        height: 100vh;
    }
}

// BACK HOME

.bannerSlider__backHome {
    position: absolute;
    top: $padding-mobile;
    left: $padding-mobile;
    z-index: 1;

    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.iconBackHome {
    display: block;
    position: relative;
    width: 32px;
    margin-right: 25px
}

.bannerSlider__textBackHome {
    @include fontHelveticaRegular;
    text-transform: uppercase;
    color: $white;
    font-size: rem(20px);
}

// SLIDER 

.bannerSlider__item {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

.bannerSlider__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// CUSTOM ARROWS

.bannerSlider {
    .splide__arrow {
        top: auto;
        bottom: 60px;
        transform: none;
        background-color: transparent;
    }

    .splide__arrow--prev {
        transform: rotate(90deg);
        position: absolute;
        top: 30px;
    }

    .splide__arrow--next {
        position: absolute;
        bottom: 30px;

        .splide__imgArrow {
            transform: rotate(270deg);
        }
    }
}

// PAGINATION - BULLETS

.bannerSlider {
    .splide__pagination {
        right: 30px;
    }
}