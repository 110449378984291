@import '../../styles';

.button {
    display: block;
    position: relative;
    border-radius: 40px;
    border: 1px solid $white;
    background-color: $black;
    width: fit-content;
    transition: $transition-ease;

    &:hover {
        background-color: $white;
    }
}

.button__link {
    display: block;
    position: relative;
    padding: 10px 12px;

}

.button__text {
    @include fontHelveticaRegular;
    font-size: 20px;
    color: $white;
    letter-spacing: 0;
    transition: $transition-ease;

    .button:hover & {
        color: $black;
    }
}