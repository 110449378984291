@import '../../styles';

.bannerQuote {
    display: block;
    position: relative;
    padding: 80px $padding-mobile;

    @include respond-above(s){
        padding: 100px $padding-tablet;
    }

    @include respond-above(m){
        padding: $padding-desktop;
    }
}

.bannerQuote__symbol {
    
    @include fontHelveticaRegular;
    font-size: rem(100px);
    color: $white;
    display: block;
    height: 80px;

    @include respond-above(s) {
        font-size: rem(140px);
        height: 100px;
    }

    @include respond-above(m) {
        font-size: rem(180px);
        height: 120px;
    }
}

.bannerQuote__cite {
    @include rich-text-general;
    @include fontHelveticaRegular;
    font-size: rem(40px);
    color: $white;

    @include respond-above(s) {
        font-size: rem(50px);
    }

    @include respond-above(m) {
        font-size: rem(60px);
    }
}