@import '../../styles';

.sixE {
    display: flex;
    align-items: center;
    position: relative;
    width: 100vw;

    @include respond-above(m) {
        height: 100vh;
    }
}

.sixE__background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;

    @include respond-above(m) {
        height: 100vh;
    }
}

.sixE__backgroundParallax {
    width: 100%;
    height: 100%;
}

.sixE__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pageSixE__logo {
    width: 56px;
    margin-right: 20px;
    fill: $white;
}

.sixE__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 60px 30px;

    @include respond-above(s) {
        padding: 150px 50px;
    }

    @include respond-above(m) {
        padding: 100px;
    }

    .pageSixE--open & {
        opacity: 0;
        pointer-events: none;
    }

}

.sixE__titleWrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;

    @include respond-above(m) {
        width: 70%;
    }
}

.sixE__title {
    @include fontHelveticaBold;
    font-size: 24px;
    color: $white;

    @include respond-above(s) {
        font-size: 26px;
    }

    @include respond-above(m) {
        font-size: 28px;
    }
}

.sixE__wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    @include respond-above(m) {
        width: 80%;
        justify-content: space-between;
    }

    &.active {
        .sixE__element {
            opacity: 0.35;
        }
    }
}

.pageSixE {
    display: block;
    position: relative;
}

.pageSixE__backButton {
    cursor: pointer;
    position: absolute;
    @include fontHelveticaRegular;
    font-size: 19px;
    text-transform: uppercase;
    color: $white;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top: $padding-mobile;
    left: $padding-mobile;
}

.pageSixE__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition-property: opacity ease;
    transition-duration: 0.4s;
    opacity: 0;
    pointer-events: none;
    z-index: 1;

    .pageSixE--open & {
        opacity: 1;
        pointer-events: visible;
        transition-duration: 1.5s;
    }
}

.pageSixE__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;

    .pageSixE--open & {
        opacity: 1;
        transition: all ease 0.3s;
    }

    &:after {
        content: "";
        height: 100%;
        width: 100%;
        background-color: $overlay-black;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.pageSixE__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $padding-mobile;

    @include respond-above(s) {
        padding: $padding-tablet;
    }

    @include respond-above(m) {
        padding: $padding-desktop;
    }
}

.pageSixE__titleWrapper {
    display: flex;
    align-items: flex-end;
    color: $white;
}

.pageSixE__title {
    display: block;
    position: relative;
    @include fontHelveticaBold;
    margin-left: 6px;
    font-size: 40px;

    @include respond-above(s) {
        margin-left: 10px;
        font-size: 80px;
    }

    @include respond-above(m) {
        margin-left: 10px;
        font-size: 100px;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20px;
        height: 5px;
        background-color: $white;

        @include respond-above(s) {
            height: 13px;
            width: 45px;
        }

        @include respond-above(m) {
            height: 15px;
            width: 55px;
        }
    }
}

.pageSixE__description {
    @include fontHelveticaRegular;
    color: $white;
    margin-top: 20px;
    width: 80%;
    font-size: 18px;

    @include respond-above(s) {
        font-size: 24px;
        width: 60%;
    }

    @include respond-above(m) {
        font-size: 28px;
    }

    @include rich-text-general;
}

.iconBackHome {
    display: block;
    position: relative;
    width: 32px;
    margin-right: 25px
}

.pageSixE__logo {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;

    @include respond-above(s) {
        width: 80px;
        height: 80px;
    }

    @include respond-above(l) {
        width: 100px;
        height: 100px;
    }
}