@import '../../styles';

.projectLinks {
    @include project-section;
    margin-bottom: 0;
}

.projectLinks__title {
    @include t-project-title;
    display: none;
}

.projectLinks__textLink {
    @include t-project-cta;
}