//–––––––––––––––––––––
//  BREAKPOINTS
//–––––––––––––––––––––

$breakpoints: (xxs_minus: 374px,
  xxs: 375px,
  xxs_plus: 376px,

  xs_minus: 479px,
  xs: 480px,
  xs_plus: 481px,

  s_minus: 767px,
  s: 768px,
  s_plus: 769px,

  m_minus: 1023px,
  m: 1024px,
  m_plus: 1025px,

  l_minus: 1279px,
  l: 1280px,
  l_plus: 1281px,

  xl_minus: 1439px,
  xl: 1440px,
  xl_plus: 1441px,

  xxl_minus: 1599px,
  xxl: 1600px,
  xxl_plus: 1601px);

//–––––––––––––––––––––
//  TRANSITIONS
//–––––––––––––––––––––

$time-transition: 0.4s;
$transition-ease: all $time-transition ease;

//–––––––––––––––––––––
//  DEFAULT COLORS
//–––––––––––––––––––––

$white: #F8F4EA;
$black: #141212;
$black-pure: #000000;
$grey: #888B8D;
$grey-2: #707070;
$green: rgba(19,122,99, 0.6);

//–––––––––––––––––––––
//  OVERLAY COLORS
//–––––––––––––––––––––

$overlay-black: rgba(0, 0, 0, 0.6);
$overlay-white: rgba(222, 222, 222, 0.6);

//–––––––––––––––––––––
//  PADDINGS INTERNAL
//–––––––––––––––––––––

$padding-mobile: 30px;
$padding-tablet: 60px;
$padding-desktop: 100px;