@import '../../styles';

.projectBannerImage {
    display: block;
    position: relative;
    width: 100vw;
    height: auto;
    margin-bottom: 100px;

    @include respond-above(s) {
        margin-bottom: 180px;
    }
}

.projectBannerImage__img {
    display: block;
    position: relative;
    width: 100vw;
    height: auto;
}