@import '../../styles';

.changeLanguage {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @include respond-above(m){
        justify-content: flex-start;
    }
}

.changeLanguage__linkLanguage {
    @include fontHelveticaRegular;
    color: $white;
    margin-right: 15px;
    font-size: 30px;
    opacity: 0.6;

    @include respond-above(s){
        font-size: 40px;
    }

    &:hover {
        cursor: pointer;
    }

    &.active {
        opacity: 1;
    }
}