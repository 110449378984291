@import '../../styles';

.sixE__element {
    display: block;
    width: 50%;
    margin-bottom: 30px;
    cursor: pointer;
    transition: $transition-ease;

    @include respond-above(s) {
        width: 33.333%;
    }

    @include respond-above(l) {
        width: 16.666%;
    }

    &.active {
        opacity: 1 !important;
        transform: scale(1.1);
    }
}

.sixE__elementLogo {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.sixE__elementImage {
    height: 100%;
}

// .sixE__elementLogo-earth{
//     background-color: red;
// }
// .sixE__elementLogo-environment{
//     background-color: blue;
// }
// .sixE__elementLogo-energy{
//     background-color: yellow;
// }
// .sixE__elementLogo-education{
//     background-color: pink;
// }
// .sixE__elementLogo-empowerment{
//     background-color: orange;
// }
// .sixE__elementLogo-economics{
//     background-color: green;
// }

.sixE__elementTitle {
    padding-bottom: 20px;
    text-align: center;
    color: $white;
    @include fontHelveticaBold;
    font-size: 20px;

    span,
    u {
        text-decoration: underline 3px;
    }
}

.sixE__logo {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;

    @include respond-above(s) {
        width: 100px;
        height: 100px;
    }


    @include respond-above(l) {
        width: 120px;
        height: 120px;
    }
}