@import '../../styles';

.projectListTextImage {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 100px;
}

.projectListTextImage__content {
    display: block;
    position: relative;
    width: 100%;
}

.projectListTextImage__block {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 120px;

    @include respond-above(s) {
        &.left {
            flex-direction: row-reverse;
        }

        &.right {
            flex-direction: row;
        }

    }
}

.projectListTextImage__blockImage,
.projectListTextImage__blockText {
    display: block;
    position: relative;
    width: 100%;

    @include respond-above(s) {
        width: 50%;
    }
}

.projectListTextImage__blockText {
    padding: 32px;
    @include respond-above(s) {
        padding: 0;
        .projectListTextImage__block.left & {
            margin-right: 20px;
            padding-left: 40px;
        }

        .projectListTextImage__block.right & {
            margin-left: 20px;
            padding-right: 40px;
        }
    }
}

.projectListTextImage__blockImage {
    margin-bottom: 30px;
    top: -60px;

    @include respond-above(s) {
        margin-bottom: 0;

        .projectListTextImage__block.left & {
            margin-left: 20px;
        }

        .projectListTextImage__block.right & {
            margin-right: 20px;
        }
    }
}

.projectListTextImage__image {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

}

// DESC

.projectListTextImage__title {
    @include fontHelveticaRegular;
    color: $white;
    font-size: rem(22px);
    margin-bottom: 20px;
    text-transform: uppercase;
    text-decoration: underline;

    @include respond-above(s) {
        font-size: rem(24px);
    }
}

.projectListTextImage__desc {
    @include fontHelveticaRegular;
    color: $white;
    font-size: rem(18px);
    margin-bottom: 20px;
    

    @include respond-above(s) {
        font-size: rem(20px);
        line-height: 20px;
        letter-spacing: 2px;
    }

    // This is the richText only for block textImage, do not use it in others places
    // If you want to use a general rich text use the mixin rich-text-general
    @include rich-text-only-block-textImage;
}