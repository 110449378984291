//–––––––––––––––––––––
//  REM CONVERTER
//–––––––––––––––––––––

$text-base-size: 16px;

@function rem($size) {
  @return #{$size / $text-base-size}rem;
}

//–––––––––––––––––––––
//  RESPOND ABOVE
//–––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  }

  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//–––––––––––––––––––––
//  RESPOND BELOW
//–––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  }

  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//–––––––––––––––––––––
//  RESPOND BEETWEEN
//–––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  }

  @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower)==false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper)==false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

//–––––––––––––––––––––
//  EXPANDED ABSOLUTE
//–––––––––––––––––––––

@mixin expanded-abs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

//–––––––––––––––––––––
//  EXPANDED FIXED
//–––––––––––––––––––––

@mixin expanded-fix {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

//–––––––––––––––––––––
//  IMAGE CROP
//–––––––––––––––––––––

@mixin allow-crop {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;

  &.focusOnTop {
    top: 0;
    transform: translate(-50%, 0%);
  }

  &.focusOnBottom {
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0%);
  }
}

//–––––––––––––––––––––
//  HIDDEN SCROLLBAR
//–––––––––––––––––––––

@mixin hidden-scrollbar {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

//–––––––––––––––––––––
//  UNDERLINE LINK
//–––––––––––––––––––––

@mixin underline-link {

  width: fit-content;

  &::after {
    content: "";
    background: none repeat scroll 0 0 transparent;
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: $white;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  &:hover:after {
    width: 100%;
    left: 0;
  }
}

//–––––––––––––––––––––
//  PROJECT SESSION
//–––––––––––––––––––––

@mixin project-section {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 50px;

  @include respond-above(m) {
    width: 50%;
  }
}

//–––––––––––––––––––––
//  RICH TEXT GENERAL
//–––––––––––––––––––––

@mixin rich-text-general {
  b,
  strong {
    font-weight: bold;
  }

  span {
    text-decoration: underline
  }

  i {
    font-style: italic;
    color: $white;
  }
}

//––––––––––––––––––––––––––––––––––––––
//  RICH TEXT ONLY FOR BLOCK TEXTIMAGE
//––––––––––––––––––––––––––––––––––––––

@mixin rich-text-only-block-textImage {
  p {
    margin-bottom: 20px
  }

  b,
  strong {
    font-weight: bold;
  }

  ul {
    margin-bottom: 20px;

    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    font-size: rem(22px);

    @include respond-above(s) {
      font-size: rem(24px);
    }
  }

  h4 {
    font-size: rem(14px);

    @include respond-above(s) {
      font-size: rem(16px);
    }
  }

  div {
    margin-bottom: 10px;
  }
}