@import '../../styles';

.projectDescription {
    @include project-section;
}

// TITLE AND SUBTITLE

.projectDescription__intro {
    margin-bottom: 50px;
}

.projectDescription__title {
    @include fontHelveticaBold;
    color: $white;
    font-size: rem(70px);
    margin-bottom: 10px;
    font-weight: normal;

    @include respond-above(s) {
        font-size: rem(90px);
    }

    @include rich-text-general;
}

.projectDescription__subtitle {
    @include t-project-title;
    margin-bottom: 30px;

    @include rich-text-general;
}

// CONTENT INFO 

.projectDescription__info {
    display: block;
    position: relative;
}

.projectDescription__text,
.projectDescription__textItemList {
    @include fontHelveticaRegular;
    color: $white;
    font-size: rem(18px);
    margin-bottom: 20px;
    line-height: 30px;
    letter-spacing: 2px;


    @include respond-above(s) {
        font-size: rem(20px);
    }

    @include rich-text-general;

    .underlineAmazonGuardian {
        font-size: 19px;
    }
}

.projectDescription__list {
    margin-bottom: 20px;
}

.projectDescription__itemList {
    display: block;
    position: relative;
    // padding-left: 30px;
    list-style-type: disc;
    color: $white;

    // &:after {
    //     content: "";
    //     position: absolute;
    //     top: 8px;
    //     left: 0;
    //     width: 8px;
    //     height: 8px;
    //     border-radius: 100%;
    //     background-color: $white;
    // }
}

.projectDescription__textItemList {
    margin-bottom: 4px
}