@import '../../styles';

.followUs {
    display: block;
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: $black;
    overflow: hidden;
    margin: 40px 0;
    padding: 0 $padding-mobile;

    @include respond-above(s) {
        height: 60vh;
        margin: 80px 0;
        padding: 0;
    }

    @include respond-above(m) {
        height: 100vh;
        margin: 100px 0;
        padding: 0 40px;
    }
}

// CONTENT

.followUs__content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    @include respond-above(s) {
        flex-direction: row;
    }
}

.followUs__left {
    background-color: $black;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    padding-top: 50px;
    padding-bottom: 90px;
    z-index: 1;

    @include respond-above(s) {
        width: 55%;
        height: 60vh;
        padding-left: $padding-tablet;
        padding-top: 0;
        padding-bottom: 0;
    }

    @include respond-above(m) {
        height: 100vh;
        padding-top: 0;
        padding-left: $padding-tablet;
    }
}

.followUs__right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    top: -280px;
    padding: 0;

    @include respond-above(s) {
        padding: 0 20px;
        width: 45%;
    }
}

// LEFT

.followUs__title {
    @include fontHelveticaRegular;
    display: block;
    font-size: rem(40px);
    color: $white;
    margin-bottom: 28px;

    @include respond-above(s) {
        font-size: rem(50px);
    }

    @include respond-above(m) {
        font-size: rem(60px);
    }
}

// RIGHT

.followUs__listImages {
    position: relative;

    &.followUs__listImages--firstColumn {
        padding: 0 20px 0 0;

        @include respond-above(s) {
            padding: 0 20px;
        }
    }

    &.followUs__listImages--secondColumn {
        top: -100%;

        padding: 0 0 0 20px;

        @include respond-above(s) {
            padding: 0 20px;
        }
    }
}

.followUs__img {
    display: block;
    margin-bottom: 32px;
}