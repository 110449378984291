@import '../../styles';

.projectVideos {
    @include project-section;
    margin-bottom: 0;
}

.projectVideos__title {
    @include t-project-title;
    display: none;
}

.projectVideos__textVideo {
    @include t-project-cta;
}