@import '../../styles';

.nextProject {
    display: block;
    position: absolute;
    bottom: $padding-mobile;
    right: $padding-mobile;
}

.nextProject__link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nextProject__text {
    @include fontHelveticaRegular;
    text-transform: uppercase;
    font-size: rem(20px);
    color: $white;
}

.nextProject__imgArrow {
    width: 32px;
    transform: rotate(180deg);
    margin-left: 25px
}