@import '../../styles';

.projectImpacts {
    @include project-section;
    margin-bottom: 20px; // Only for this section
}

.projectImpacts__title {
    @include t-project-title;
}

.projectImpacts__info {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
}

.projectImpacts__impact {
    display: block;
    position: relative;
    width: 50%;
    padding-bottom: 30px;
    padding-right: 30px;
}

.projectImpacts__impactTitle {
    @include fontHelveticaRegular;
    font-size: rem(24px);
    color: $white;

    @include rich-text-general;
}

.projectImpacts__impactSubtitle {
    @include fontHelveticaRegular;
    font-size: rem(16px);
    color: $white;
    line-height: 20px;
    letter-spacing: 2px;

    @include rich-text-general;
}