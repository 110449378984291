@import '../../styles';

.partners__element {
    display: block;
    height: 150px;
    width: 33.333%;
    padding: 16px;

    @include respond-above(s) {
        padding: 20px;
        width: 25%;
    }

    @include respond-above(m) {
        width: 20%;
    }
}

.partners__goTo {
    display: flex;
    justify-content: center;
    height: 100%;
}