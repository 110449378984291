@import '../../styles';

.bannerVideo {
    display: block;
    position: relative;
    width: 100vw;
    height: 60vh;

    @include respond-above(s){
        height: 60vh;
    }

    @include respond-above(m){
        height: 100vh;
    }
}

.bannerVideo__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.bannerVideo__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}