@import '../../styles';

.slider {
    padding: 100px 0;

    @include respond-above(s) {
        padding: 120px 0;
    }

    @include respond-above(m) {
        padding: 120px 0;
    }
}

.slider__title {
    @include fontHelveticaBold;
    font-size: rem(40px);
    color: $white;
    margin-bottom: 80px; 
    margin-left: $padding-mobile;
    margin-right: $padding-mobile;

    @include respond-above(s) {
        margin-bottom: 100px; 
        margin-left: $padding-tablet;
        margin-right: $padding-tablet;
        font-size: rem(50px);
    }

    @include respond-above(m) {
        margin-bottom: $padding-desktop;
        margin-left: $padding-desktop;
        margin-right: $padding-desktop;
        font-size: rem(60px);
    }
}

.slider__item {
    display: block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    transition: $transition-ease;

    &:after {
        content: "";
        @include expanded-abs;
        background-color: transparent;
        transition: $transition-ease;
        z-index: 1;
    }

    &:hover {
        &:after {
            background-color: $overlay-black;
        }

        .slider__img {
            transform: scale(1.1);
        }

        .slider__info {
            opacity: 1;
        }
    }
}

.slider__info {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: $transition-ease;
    opacity: 0;
    z-index: 2;
}

.slider__itemTitle,
.slider__itemSubtitle {
    text-align: center;
    color: $white;
}

.slider__itemTitle {
    @include fontHelveticaBold;
    font-weight: lighter;
    margin-bottom: 15px;
    white-space: nowrap;
    font-size: rem(26px);

    @include respond-above(s) {
        font-size: rem(30px);
    }

    @include respond-above(m) {
        font-size: rem(36px);
    }

    @include respond-above(l) {
        font-size: rem(42px);
    }

    @include rich-text-general;
}

.slider__itemSubtitle {
    @include fontHelveticaRegular;
    font-size: rem(16px);

    @include respond-above(s) {
        font-size: rem(18px);
    }

    @include respond-above(m) {
        font-size: rem(20px);
    }
}

// TILT

.slider__tilt {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 40px;
}

// IMAGE

.slider__img {
    display: block;
    width: 100%;
    transition: $transition-ease;
}

// CUSTOM ARROWS

.slider {
    .splide__arrow {
        top: auto;
        bottom: -60px;
        transform: none;
        background-color: transparent;
    }

    .splide__arrow--prev {
        right: 80px;
        left: auto;
        @include respond-above(s){
            right: 130px;
        }
    }

    .splide__arrow--next {
        right: 30px;
        left: auto;
        @include respond-above(s){
            right: 80px;
        }

        .splide__imgArrow {
            transform: rotate(180deg);
        }
    }
}